import { dayjsDate, formatDate } from '@utils';
import dayjs from 'dayjs';

export function getDefaultProgrammationDateInterventionFilterParams(): any {
  return {
    filters: [
      {
        filterParams: {
          defaultOption: 'inYearRange',
          filterOptions: [...customProgrammationDateInterventionFilter]
        }
      },
      {
        filter: 'agSetColumnFilter',
        filterParams: {
          valueFormatter: params => (params.value ? formatDate(params.value, 'YYYY') : 'Blanc'),
          comparator: (a, b) => {
            const dateA = dayjsDate(a)?.year() as number;
            const dateB = dayjsDate(b)?.year() as number;
            return dateA < dateB ? -1 : dateA > dateB ? 1 : !dateA && dateB ? 1 : !dateB && dateA ? -1 : 0;
          }
        }
      }
    ]
  };
}

export const customProgrammationDateInterventionFilter = [
  {
    displayKey: 'inYearRange',
    displayName: "Dans l'intervalle",
    numberOfInputs: 2,
    hideFilterInput: true,
    predicate: (filterValue: [string, string], cellValue: any): boolean => {
      const value = dayjs(cellValue).year();
      return value >= +filterValue[0] && value <= +filterValue[1];
    }
  },
  {
    displayKey: 'inYear',
    displayName: "Dans l'année",
    numberOfInputs: 0,
    hideFilterInput: true,
    predicate: (_filterValue: any, cellValue: any): boolean => {
      const value = dayjs(cellValue).year();
      const currentYear = dayjs().year();
      return value === currentYear;
    }
  },
  {
    displayKey: 'in2Year',
    displayName: 'Dans les 2 ans',
    numberOfInputs: 0,
    hideFilterInput: true,
    predicate: (_filterValue: any, cellValue: any): boolean => {
      const value = dayjs(cellValue).year();
      const currentYear = dayjs().year();
      return value >= currentYear && value <= currentYear + 2;
    }
  },
  {
    displayKey: 'in5Year',
    displayName: 'Dans les 5 ans',
    numberOfInputs: 0,
    hideFilterInput: true,
    predicate: (_filterValue: any, cellValue: any): boolean => {
      const value = dayjs(cellValue).year();
      const currentYear = dayjs().year();
      return value >= currentYear && value <= currentYear + 5;
    }
  },
  {
    displayKey: 'in10Year',
    displayName: 'Dans les 10 ans',
    numberOfInputs: 0,
    hideFilterInput: true,
    predicate: (_filterValue: any, cellValue: any): boolean => {
      const value = dayjs(cellValue).year();
      const currentYear = dayjs().year();
      return value >= currentYear && value <= currentYear + 10;
    }
  }
];
