import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { User, UserEntityState } from '@get/api-interfaces';
import { SocieteTerritoireUser, SocieteTerritoireUserEntityState } from '@get/api-interfaces';
import { CampagneUser, CampagneUserEntityState } from '@get/api-interfaces';
import { ComposantAttendu, ComposantAttenduEntityState } from '@get/api-interfaces';
import { Campagne, CampagneEntityState } from '@get/api-interfaces';
import { UserSocieteProfil, UserSocieteProfilEntityState } from '@get/api-interfaces';
import { SocieteProfil, SocieteProfilEntityState } from '@get/api-interfaces';
import { UserPatrimoine, UserPatrimoineEntityState } from '@get/api-interfaces';
import { Patrimoine, PatrimoineEntityState } from '@get/api-interfaces';
import { Composant, ComposantEntityState } from '@get/api-interfaces';
import { Valeur, ValeurEntityState } from '@get/api-interfaces';
import { UserNewsletter, UserNewsletterEntityState } from '@get/api-interfaces';
import { Newsletter, NewsletterEntityState } from '@get/api-interfaces';
import { SocieteConfigAnalyseSynthese, SocieteConfigAnalyseSyntheseEntityState } from '@get/api-interfaces';
import { Organisation, OrganisationEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { UserState } from '@get/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';

export const userRelations: string[] = ['societeTerritoireUsers','campagneUsers','composantAttendus','campagnes','userSocieteProfils','societeProfils','userPatrimoines','patrimoines','composants','valeurs','userNewsletters','newsletters','societeConfigAnalyseSyntheses','organisations',];

export const { selectEntities, selectAll } = UserState.adapter.getSelectors();

export const selectUserState = createFeatureSelector<UserState.IState>(UserState.userFeatureKey);

export const selectIsLoadedUser = createSelector(
  selectUserState,
  (state: UserState.IState) => state.isLoaded
);

export const selectIsLoadingUser = createSelector(
  selectUserState,
  (state: UserState.IState) => state.isLoading
);

export const selectIsReadyUser = createSelector(
  selectUserState,
  (state: UserState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedUser = createSelector(
  selectUserState,
  (state: UserState.IState) => state.isLoaded && !state.isLoading
);

export const selectUsersEntities = createSelector(selectUserState, selectEntities);

export const selectUsersArray = createSelector(selectUserState, selectAll);

export const selectIdUsersActive = createSelector(
  selectUserState,
  (state: UserState.IState) => state.actives ?? []
);

const usersInObject = (users: Dictionary<UserEntityState>) => ({ users })

const selectUsersEntitiesDictionary = createSelector(selectUsersEntities, usersInObject);

const selectAllUsersObject = createSelector(selectUsersEntities, users => {
  return hydrateAll({ users });
});

const selectOneUserDictionary = (idUser : number) =>
  createSelector(selectUsersEntities, users => ({
    users: { [idUser]: users[idUser] }
  }));

const selectOneUserDictionaryWithoutChild = (idUser : number) =>
  createSelector(selectUsersEntities, users => ({
    user: users[idUser]
  }));

const selectActiveUsersEntities = createSelector(
  selectIdUsersActive,
  selectUsersEntities,
  (actives: number[], users: Dictionary<UserEntityState>) => getUsersFromActives(actives, users)
);

function getUsersFromActives(
  actives: number[],
  users: Dictionary<UserEntityState>
): Dictionary<UserEntityState> {
  return actives.reduce((acc, idActive) => {
    if (users[idActive]) {
      acc[idActive] = users[idActive];
    }
    return acc;
  }, {} as Dictionary<UserEntityState>);
}

const selectAllUsersSelectors: Dictionary<Selector> = {};
export function selectAllUsers(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<User>(
      schema,
      selectAllUsersSelectors,
      selectUsersEntitiesDictionary,
      getRelationSelectors,
      userRelations,
      hydrateAll,
      'user'
    );
  } else {
    return selectAllUsersObject;
  }
}

export function selectAllUsersDictionary(
  schema: SelectSchema = {},
  customKey = 'users'
): Selector {
  return createSelector(selectAllUsers(schema), result => {
    const res = { [customKey]: {} as Dictionary<UserEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.users.length; i++) {
      res[customKey][result.users[i].idUser] = result.users[i];
    }
    return res;
  });
}

export function selectOneUser(
  schema: SelectSchema = {},
  idUser: number
): Selector {
  if (schema.include) {
  const selectors: Selector[] = [selectOneUserDictionary(idUser)];
  selectors.push(...getRelationSelectors(schema, userRelations, 'user'));
  return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneUserDictionaryWithoutChild(idUser);
  }
}

export function selectActiveUsers(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveUsersEntities, users => ({
      users
    }))
  ];
  selectors.push(...getRelationSelectors(schema, userRelations, 'user'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  users: Dictionary<UserEntityState>;
  organisations?: Dictionary<OrganisationEntityState>;
  societeTerritoireUsers?: Dictionary<SocieteTerritoireUserEntityState>;
  campagneUsers?: Dictionary<CampagneUserEntityState>;
  composantAttendus?: Dictionary<ComposantAttenduEntityState>;
  campagnes?: Dictionary<CampagneEntityState>;
  userSocieteProfils?: Dictionary<UserSocieteProfilEntityState>;
  societeProfils?: Dictionary<SocieteProfilEntityState>;
  userPatrimoines?: Dictionary<UserPatrimoineEntityState>;
  patrimoines?: Dictionary<PatrimoineEntityState>;
  composants?: Dictionary<ComposantEntityState>;
  valeurs?: Dictionary<ValeurEntityState>;
  userNewsletters?: Dictionary<UserNewsletterEntityState>;
  newsletters?: Dictionary<NewsletterEntityState>;
  societeConfigAnalyseSyntheses?: Dictionary<SocieteConfigAnalyseSyntheseEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { users: (User | null)[] } {
  const {
    users,
    organisations,
    societeTerritoireUsers,
    campagneUsers,
    composantAttendus,
    campagnes,
    userSocieteProfils,
    societeProfils,
    userPatrimoines,
    patrimoines,
    composants,
    valeurs,
    userNewsletters,
    newsletters,
    societeConfigAnalyseSyntheses
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    users: Object.keys(users).map(idUser =>
      hydrate(
        users[idUser] as UserEntityState,
        organisations,
        societeTerritoireUsers,
        campagneUsers,
        composantAttendus,
        campagnes,
        userSocieteProfils,
        societeProfils,
        userPatrimoines,
        patrimoines,
        composants,
        valeurs,
        userNewsletters,
        newsletters,
        societeConfigAnalyseSyntheses
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { user: UserEntityState | null } {
  const {
    users,
    organisations,
    societeTerritoireUsers,
    campagneUsers,
    composantAttendus,
    campagnes,
    userSocieteProfils,
    societeProfils,
    userPatrimoines,
    patrimoines,
    composants,
    valeurs,
    userNewsletters,
    newsletters,
    societeConfigAnalyseSyntheses
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const user = Object.values(users)[0];
  return {
    user: hydrate(
      user as UserEntityState,
      organisations,
      societeTerritoireUsers,
      campagneUsers,
      composantAttendus,
      campagnes,
      userSocieteProfils,
      societeProfils,
      userPatrimoines,
      patrimoines,
      composants,
      valeurs,
      userNewsletters,
      newsletters,
      societeConfigAnalyseSyntheses
    )
  };
}

function hydrate(
  user: UserEntityState,
  organisationEntities?: Dictionary<OrganisationEntityState>,
  societeTerritoireUserEntities?: Dictionary<SocieteTerritoireUserEntityState>,
  campagneUserEntities?: Dictionary<CampagneUserEntityState>,
  composantAttenduEntities?: Dictionary<ComposantAttenduEntityState>,
  campagneEntities?: Dictionary<CampagneEntityState>,
  userSocieteProfilEntities?: Dictionary<UserSocieteProfilEntityState>,
  societeProfilEntities?: Dictionary<SocieteProfilEntityState>,
  userPatrimoineEntities?: Dictionary<UserPatrimoineEntityState>,
  patrimoineEntities?: Dictionary<PatrimoineEntityState>,
  composantEntities?: Dictionary<ComposantEntityState>,
  valeurEntities?: Dictionary<ValeurEntityState>,
  userNewsletterEntities?: Dictionary<UserNewsletterEntityState>,
  newsletterEntities?: Dictionary<NewsletterEntityState>,
  societeConfigAnalyseSyntheseEntities?: Dictionary<SocieteConfigAnalyseSyntheseEntityState>,
): User | null {
  if (!user) {
    return null;
  }

  const userHydrated: UserEntityState = { ...user };
  if (organisationEntities) {
    userHydrated.organisation = organisationEntities[user.organisation as number] as Organisation;
  } else {
    delete userHydrated.organisation;
  }

  if (societeTerritoireUserEntities) {
    userHydrated.societeTerritoireUsers = ((userHydrated.societeTerritoireUsers as number[]) || []).map(
      id => societeTerritoireUserEntities[id]
    ) as SocieteTerritoireUser[];
  } else {
    delete userHydrated.societeTerritoireUsers;
  }

  if (campagneUserEntities) {
    userHydrated.campagneUsers = ((userHydrated.campagneUsers as number[]) || []).map(
      id => campagneUserEntities[id]
    ) as CampagneUser[];
  } else {
    delete userHydrated.campagneUsers;
  }

  if (composantAttenduEntities) {
    userHydrated.composantAttendus = ((userHydrated.composantAttendus as number[]) || []).map(
      id => composantAttenduEntities[id]
    ) as ComposantAttendu[];
  } else {
    delete userHydrated.composantAttendus;
  }

  if (campagneEntities) {
    userHydrated.campagnes = ((userHydrated.campagnes as number[]) || []).map(
      id => campagneEntities[id]
    ) as Campagne[];
  } else {
    delete userHydrated.campagnes;
  }

  if (userSocieteProfilEntities) {
    userHydrated.userSocieteProfils = ((userHydrated.userSocieteProfils as number[]) || []).map(
      id => userSocieteProfilEntities[id]
    ) as UserSocieteProfil[];
  } else {
    delete userHydrated.userSocieteProfils;
  }

  if (societeProfilEntities) {
    userHydrated.societeProfils = ((userHydrated.societeProfils as number[]) || []).map(
      id => societeProfilEntities[id]
    ) as SocieteProfil[];
  } else {
    delete userHydrated.societeProfils;
  }

  if (userPatrimoineEntities) {
    userHydrated.userPatrimoines = ((userHydrated.userPatrimoines as number[]) || []).map(
      id => userPatrimoineEntities[id]
    ) as UserPatrimoine[];
  } else {
    delete userHydrated.userPatrimoines;
  }

  if (patrimoineEntities) {
    userHydrated.patrimoines = ((userHydrated.patrimoines as number[]) || []).map(
      id => patrimoineEntities[id]
    ) as Patrimoine[];
  } else {
    delete userHydrated.patrimoines;
  }

  if (composantEntities) {
    userHydrated.composants = ((userHydrated.composants as number[]) || []).map(
      id => composantEntities[id]
    ) as Composant[];
  } else {
    delete userHydrated.composants;
  }

  if (valeurEntities) {
    userHydrated.valeurs = ((userHydrated.valeurs as number[]) || []).map(
      id => valeurEntities[id]
    ) as Valeur[];
  } else {
    delete userHydrated.valeurs;
  }

  if (userNewsletterEntities) {
    userHydrated.userNewsletters = ((userHydrated.userNewsletters as number[]) || []).map(
      id => userNewsletterEntities[id]
    ) as UserNewsletter[];
  } else {
    delete userHydrated.userNewsletters;
  }

  if (newsletterEntities) {
    userHydrated.newsletters = ((userHydrated.newsletters as number[]) || []).map(
      id => newsletterEntities[id]
    ) as Newsletter[];
  } else {
    delete userHydrated.newsletters;
  }

  if (societeConfigAnalyseSyntheseEntities) {
    userHydrated.societeConfigAnalyseSyntheses = ((userHydrated.societeConfigAnalyseSyntheses as number[]) || []).map(
      id => societeConfigAnalyseSyntheseEntities[id]
    ) as SocieteConfigAnalyseSynthese[];
  } else {
    delete userHydrated.societeConfigAnalyseSyntheses;
  }

  return userHydrated as User;
}
